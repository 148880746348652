


































































































































































































































































// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import * as _ from 'lodash';
import { APPSLIST_WITH_CAMPAIGNS } from '../../utils/apollo-queries';
import Vue from 'vue';
import { AdGroupSelectorContext } from '../../utils/enums';

export default Vue.extend({
  name: 'SelectAdGroup',
  props: {
    settings: {
      // this object also includes now property which is current timestamp.
      // this is to trigger change detection even if values are unchanged in the passed object.
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },

  computed: {
    a: function (): string {
      return 'a';
    },
  },

  watch: {
    settings: function (data) {
      this.show = true;
      this.fullscreenLoader = this.$loading.show();
      if (data.arIndex != null) {
        this.arIndex = data.arIndex;
      } else {
        this.arIndex = null;
      }
      if (data.keysFromKai?.length > 0) {
        this.keysFromKai = data.keysFromKai;
      }
      if (data.selectedAdgroup?.length > 0) {
        this.selectedAdgroups = data.selectedAdgroup;
      }
    },
  },

  data() {
    return {
      totalSelectedGroups: 0,
      show: false,
      appsListWithCampaigns: null,
      selectedCampaign: null,
      totalCampaigns: 0,
      totalListedGroups: 0,
      onlyOneAdgroupSnack: false,
      keysFromKai: [],

      //temporarily added to remove errors
      valid: false,
      none: '',
      panel: [],
      arIndex: null,

      selectedAdgroups: null,
      selectedApp: null,
      fullscreenLoader: null,
    };
  },

  methods: {
    deleteAdgroup(d){
      this.appsListWithCampaigns.map((app) =>
        app.campaigns.map((campaign) =>
          campaign.adgroups.map((group) => {
            if(group.id === d.id) group.selected = false
          })
        )
      );
      this.oncheck(d)
    },
    appSelectionChanged(e) {
      this.selectedApp = e;
    },

    listAdgroups(campaign) {
      this.selectedCampaign = campaign;

      this.totalListedGroups = this.selectedCampaign.adgroups.length;
    },

    oncheck(adgroup): void {
      //console.log('oncheck',adgroup);
      if (adgroup.selected) {
        if (
          this.settings.context === AdGroupSelectorContext.CREATIVESET &&
          this.totalSelectedGroups > 0
        ) {
          this.onlyOneAdgroupSnack = true;
          adgroup.selected = false;
        }
      }

      // summing up all selected groups
      // this.totalSelectedGroups = [].concat(...this.appsListWithCampaigns.map(app => app.campaigns.map(campaign => campaign.adgroups.reduce((previous, current) => current.selected === true ? 1 : 0 + previous, 0)))).reduce((p, c) => p + c);

      const toEmit = _.cloneDeep(this.appsListWithCampaigns);
      toEmit.forEach((app) =>
        app.campaigns.forEach(
          (campaign) =>
            (campaign.adgroups = campaign.adgroups
              .filter((group) => group.selected)
              // need to omit property 'selected' since it's only used for binding in component's state
              .map((group) => _.omit(group, ['selected'])))
        )
      );

      const adgroups = this.getNested(toEmit, 'ADGROUP');
      this.totalSelectedGroups = adgroups?.length || 0;
    },

    getNested(appsWithCampaigns, level) {
      // returning nested data as requested (apps > campaigns > adgroups)
      return level === 'APP'
        ? appsWithCampaigns
        : [].concat(
            ...appsWithCampaigns.map((app) =>
              level === 'CAMPAIGN'
                ? app.campaigns
                : [].concat(
                    ...app.campaigns.map((campaign) => campaign.adgroups)
                  )
            )
          );
    },

    clearAll() {
      this.totalSelectedGroups = 0;

      this.appsListWithCampaigns.map((app) =>
        app.campaigns.map((campaign) =>
          campaign.adgroups.map((group) => (group.selected = false))
        )
      );
    },

    save() {
      const toEmit = _.cloneDeep(this.appsListWithCampaigns);
      toEmit.forEach((app) =>
        app.campaigns.forEach(
          (campaign) =>
            (campaign.adgroups = campaign.adgroups
              .filter((group) => group.selected)
              // need to omit property 'selected' since it's only used for binding in component's state
              .map((group) => _.omit(group, ['selected'])))
        )
      );
      //console.log(this.keysFromKai);
      if (this.keysFromKai?.length > 0) {
        this.$emit('keysFromKai', { keys: this.keysFromKai, camps: toEmit });
      } else if (this.arIndex != null) {
        const toEmitWithIndex = {
          data: toEmit,
          index: this.arIndex,
        };
        this.$emit('onAdgroupSelection', toEmitWithIndex);
      } else {
        const toEmitWithAppId = {
          data: toEmit,
          selectedApp: this.selectedApp,
        };
        this.$emit('onAdgroupSelection', toEmitWithAppId);
      }

      this.close();
    },

    close() {
      this.show = false;
      this.arIndex = null;
      this.appsListWithCampaigns = null;
      this.selectedCampaign = null;
      this.totalCampaigns = 0;
      this.totalSelectedGroups = 0;
      this.totalListedGroups = 0;
      this.keysFromKai = [];
    },

    expandAll() {
      this.panel = [...Array(this.appsListWithCampaigns).keys()].map(
        (k, i) => i
      );
    },

    collapseAll() {
      this.panel = [];
    },

    checkSelectedAdgroup() {
      if (this.selectedAdgroups != null) {
        this.appsListWithCampaigns.map((app) =>
          app.campaigns.map((campaign) =>
            campaign.adgroups.map((group) => {
              for (const adgroup of this.selectedAdgroups)
                if (group.id === adgroup.id) {
                  group.selected = true;
                }
            })
          )
        );

        const toEmit = _.cloneDeep(this.appsListWithCampaigns);
        toEmit.forEach((app) =>
          app.campaigns.forEach(
            (campaign) =>
              (campaign.adgroups = campaign.adgroups
                .filter((group) => group.selected)
                // need to omit property 'selected' since it's only used for binding in component's state
                .map((group) => _.omit(group, ['selected'])))
          )
        );

        const adgroups = this.getNested(toEmit, 'ADGROUP');
        this.totalSelectedGroups = adgroups?.length || 0;
      }
    },
  },
  apollo: {
    getAppsListWithCampaigns: {
      query: APPSLIST_WITH_CAMPAIGNS,
      skip() {
        return !this.show;
      },
      update({ getAppsListWithCampaigns }) {
        this.appsListWithCampaigns = _.cloneDeep(getAppsListWithCampaigns);
        this.totalCampaigns = this.appsListWithCampaigns.reduce(
          (p, c) => p + c.campaigns.length,
          0
        );
        this.fullscreenLoader.hide();
        this.checkSelectedAdgroup();
      },
      error(error) {
        this.fullscreenLoader.hide();
      },
    },
  },
});
